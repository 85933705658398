<template>
  <div class="home">
    <div class="header">
      <div class="header-box flex space-between">
        <div class="flex">
          <div class="header-icon">
            <img src="../assets/images/home/logo.png" alt=""  class="logo-img" @click="home"/>
          </div>

          <div class="tabs">
            <div
              v-for="(item, index) in tabsList"
              :key="index"
              @click="handleSelect(index, item)"
              @mouseenter="onMouseEnter(index)"
              class="tabs-item"
            >
              <div class="box">
                <div
                  class="name"
                  :style="{
                    color: activeIndex == index + 1 ? '#0F3391' : '#707070',
                  }"
                >
                  {{ item.name }}
                </div>
                <img
                  src="../assets/images/home/top-right.png"
                  class="icon"
                  v-if="item.type == 'select' && !item.active"
                />
                <img
                  src="../assets/images/home/top-bottom.png"
                  class="icon"
                  v-if="item.type == 'select' && item.active"
                />
              </div>
              <div
                v-if="activeIndex == index + 1"
                class="line"
                :style="{ width: activeIndex == 1 ? '24px' : '48px' }"
              ></div>
              <div v-else class="line" style="background: #fff"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="model"
      v-if="show && tabsList[1].active"
      @mouseleave="onMouseLeave(1)"
    >
      <div class="model-box">
        <!-- 人员位置管理 -->
        <div class="people">
          <div
            class="title"
            @click="goSolution(1001)"
            style="font-weight: bold"
            :style="{ color: activeId == 1001 ? '#0f3391' : '#2c2c2c' }"
          >
            人员位置管理
          </div>
          <div
            class="text"
            @click="goSolution(1)"
            :style="{ color: activeId == 1 ? '#0f3391' : '#2c2c2c' }"
          >
            外勤人员
          </div>
          <div
            class="text"
            @click="goSolution(2)"
            :style="{ color: activeId == 2 ? '#0f3391' : '#2c2c2c' }"
          >
            室内人员
          </div>
        </div>
        <!-- 车辆位置管理 -->
        <div class="car">
          <div
            class="title"
            @click="goSolution(1002)"
            :style="{ color: activeId == 1002 ? '#0f3391' : '#2c2c2c' }"
          >
            车辆位置管理
          </div>
          <div class="list">
            <div
              v-for="(item, index) in carList"
              :key="index"
              class="item"
              @click.stop="goSolution(item.url)"
              :style="{ color: activeId == item.url ? '#0f3391' : '#2c2c2c' }"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <!-- 资产位置管理 -->
        <div class="people">
          <div
            class="title"
            @click="goSolution(1003)"
            :style="{ color: activeId == 1003 ? '#0f3391' : '#2c2c2c' }"
          >
            资产位置管理
          </div>
          <div
            class="text"
            @click="goSolution(11)"
            :style="{ color: activeId == 11 ? '#0f3391' : '#2c2c2c' }"
          >
            贵重物品
          </div>
          <div
            class="text"
            @click="goSolution(12)"
            :style="{ color: activeId == 12 ? '#0f3391' : '#2c2c2c' }"
          >
            固定资产
          </div>
          <div
            class="text"
            @click="goSolution(13)"
            :style="{ color: activeId == 13 ? '#0f3391' : '#2c2c2c' }"
          >
            包装设备
          </div>
          <div
            class="text"
            @click="goSolution(14)"
            :style="{ color: activeId == 14 ? '#0f3391' : '#2c2c2c' }"
          >
            工程设备
          </div>
        </div>
      </div>
    </div>
    <div
      class="model"
      v-if="show && tabsList[2].active"
      @mouseleave="onMouseLeave(2)"
    >
      <div class="model-box">
        <div class="people">
          <div class="title" @click="goProduct(1, 0, 0)">人员定位终端</div>
          <div class="text" @click="goProduct(1, 0, 0)"  :style="{ color: activePId == 1 ? '#0f3391' : '#2c2c2c' }">穿戴产品</div>
        </div>
        <div class="people">
          <div class="title" @click="goProduct(2, 1, 0)">车辆定位终端</div>
          <div class="text" @click="goProduct(2, 1, 0)"  :style="{ color: activePId == 2 ? '#0f3391' : '#2c2c2c' }">超长待机</div>
          <div class="text" @click="goProduct(3, 1, 1)"  :style="{ color: activePId == 3 ? '#0f3391' : '#2c2c2c' }">有线产品</div>
          <div class="text" @click="goProduct(4, 1, 2)"  :style="{ color: activePId == 4 ? '#0f3391' : '#2c2c2c' }">视频产品</div>
        </div>
        <div class="people">
          <div class="title" @click="goProduct(5, 2, 0)">资产定位终端</div>
          <div class="text" @click="goProduct(5, 2, 0)"  :style="{ color: activePId == 5 ? '#0f3391' : '#2c2c2c' }" style="padding-left:0">便携可充电无线产品</div>
        </div>
        <div class="people">
          <div class="title" @click="goProduct(6, 3, 0)">其他</div>
          <div class="text" @click="goProduct(6, 3, 0)"  :style="{ color: activePId == 6 ? '#0f3391' : '#2c2c2c' }"  style="padding-left:0">电摩产品</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: Number,
      // default: () => "1",
    },
  },
  watch: {
    show(val) {
      console.log(localStorage.getItem("solutionId"))
      if(val){
        if(this.tabsList[1].active){
          this.activeId = localStorage.getItem("solutionId") ? localStorage.getItem("solutionId") : '1001'
        }
        if(this.tabsList[2].active){
          this.activePId = localStorage.getItem("prodId") ? localStorage.getItem("prodId") : '1001'
        }
      }
    },
  },
  data() {
    return {
      show: true,
      activeIndex: 1,
      activeId:"",
      activePId:"",
      carList: [
        {
          text: "汽车金融风控",
          url: "3",
        },
        {
          text: "网约租赁车辆",
          url: "7",
        },
        {
          text: "外勤作业车辆",
          url: "4",
        },
        {
          text: "政企公车",
          url: "8",
        },
        {
          text: "应急保障车辆",
          url: "6",
        },
        {
          text: "工程车辆",
          url: "9",
        },
        {
          text: "货运物流车辆",
          url: "5",
        },
        {
          text: "特种车辆",
          url: "10",
        },
      ],
      tabsList: [
        { name: "首页", path: "/" },
        { name: "解决方案", path: "/solution", type: "select", active: false },
        { name: "产品中心", path: "/product", type: "select", active: false },
        { name: "客户案例", path: "/customerCases" },
        { name: "资讯中心", path: "/newsCenter" },
        { name: "关于我们", path: "/aboutMine" },
      ],
    };
  },
  mounted() {
    this.activeIndex = this.active;
    console.log(this.$route.path, "currentPathcurrentPath");
  },
  methods: {
    home(){
       this.$router.replace(`/`);
    },
    goSolution(id) {
       this.activeId = id
      localStorage.setItem("solutionId", id)
      this.tabsList[1].active = false;
      this.$router.replace(`/solution?id=${id}`);
      if (this.$route.path == "/solution") {
        this.$emit("change", id);
      }
        this.show = false;
    },
    goProduct(id, index, i) {
      this.activePId = id
      this.tabsList[2].active = false;
      if (this.$route.path == "/product") {
        // this.$router.replace(`/product`);
        this.$emit("change", {
          id: id,
          index: index,
          i: i,
        });
      } else {
        let data = {
          id: id,
          index: index,
          i: i,
        };
        sessionStorage.setItem("info", JSON.stringify(data));
        this.$router.replace(`/product`);
      }
       this.show = false;
    },

    handleSelect(index, item) {
      this.activeIndex = index + 1;
      this.$router.push(item.path);
    },
    onMouseEnter(index) {
      this.show = true;
      this.tabsList[index].active = true;
      if (index == 1) {
        this.tabsList[2].active = false;
      } else {
        this.tabsList[1].active = false;
      }
      if ([0, 3, 4, 5].includes(index)) {
        this.tabsList[1].active = false;
        this.tabsList[2].active = false;
      }
    },
    onMouseLeave(index) {
      this.show = false;
      this.tabsList[index].active = false;
    },
  },
};
</script>
<style lang="less" scoped>
.logo-img{
cursor: pointer;
}
.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 64px;
  background-color: #fff;
  z-index: 100;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  .header {
    width: 1200px;
    min-width: 1200px;
    flex-shrink: 0;
    margin: 0 auto;
  }

  // 主导航
  .header-box {
    background-color: #fff;
    box-sizing: border-box;
    height: 64px;
    .header-icon {
      width: 144px;
      height: 64px;
      img {
        width: 144px;
        height: 64px;
      }
    }
  }
  .is-active {
    background-color: #fff;
  }
}
.tabs {
  display: flex;
  margin-left: 64px;
  .tabs-item {
    // width: 120px;
    margin-right: 48px;
    height: 64px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-left: 3px;
  }
  .name {
    height: 24px;
    font-size: 18px;
  }

  .line {
    width: 24px;
    height: 2px;
    background: #0f3391;
    border-radius: 1px 1px 1px 1px;
    margin: 0 auto;
    margin-top: 5px;
  }
}
.box:hover {
  .name {
    color: #0f3391 !important;
  }
}
.box {
  display: flex;
}
// 遮罩层
.model {
  background-color: #fafafa;
  height: 340px;

  width: 100vw;

  .model-box {
    width: 1200px;
    margin: 0 auto;
    height: 340px;
    transition: transform 0.5s ease;
    display: flex;
  }
  .people {
    padding-top: 32px;
    margin-right: 56px;
    .title {
      width: 160px;
      height: 72px;
      border-bottom: 1px solid #cdcdcd;
      line-height: 72px;
      font-size: 18px;
      color: #2c2c2c;
      cursor: pointer;
      font-weight: bold;
    }
    .text {
      font-size: 16px;
      color: #2c2c2c;
      margin-top: 22px;
      padding-left: 20px;
      cursor: pointer;
    }
  }
  .car {
    padding-top: 32px;
    margin-right: 56px;
    .title {
      width: 264px;
      height: 72px;
      border-bottom: 1px solid #cdcdcd;
      line-height: 72px;
      font-size: 18px;
      color: #2c2c2c;
      cursor: pointer;
      font-weight: bold;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      width: 264px;
      font-size: 16px;
      color: #2c2c2c;
    }
    .item {
      margin-right: 48px;
      margin-top: 22px;
      cursor: pointer;
    }
    .item:nth-child(2n) {
      margin-right: 0px;
    }
  }
}
</style>
