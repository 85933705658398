<template>
  <div class="page" id="page">
    <heade :active="1"></heade>
    <div style="height: 64px"></div>
    <!-- 首页轮播图 -->
    <div class="banner">
      <el-carousel
        :interval="3000"
        arrow="always"
        :height="bannerHeight + 'px'"
        @change="changeCarousel($event)"
      >
        <el-carousel-item
          v-for="item in bannerList"
          :key="item"
          :height="bannerHeight + 'px'"
        >
          <div class="carousel-item">
            <img
              :src="item"
              style="width: 100vw"
              alt=""
              ref="bannerHeight"
              @load="imgLoag"
            />
            <div class="img-text">
              <img
                src="../assets/images/home/bannerT1.png"
                alt=""
                v-if="carouselIndex == 0"
              />
              <img
                src="../assets/images/home/bannerT2.png"
                alt=""
                v-if="carouselIndex == 1"
                style="width: 575px; height: 182px"
              />
              <img
                src="../assets/images/home/bannerT3.png"
                alt=""
                v-if="carouselIndex == 2"
                style="width: 415px; height: 371px"
              />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 主营业务 -->
    <div class="business">
      <div class="business-title">
        <div class="business-name">主营业务</div>
        <div class="eng">Main business</div>
      </div>
      <div class="business-img">
        <div
          v-for="(item, index) in businessList"
          :key="index"
          class="business-item"
          @click="clickBusiness(item.id)"
        >
          <img class="img" :src="item.url" alt="" />
          <div class="business-item-name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <!-- 电脑展示定位 -->
    <div class="location">
      <div class="location-content">
        <div class="location-desc">
          <div class="desc-name">北斗智能设备+云平台，</div>
          <div class="desc-name">为客户提供专业的行业数字化解决方案</div>
          <div class="desc-name-eng" style="margin-top: 16px">
            Beidou intelligent device + cloud platform,
          </div>
          <div class="desc-name-eng">
            Provide customers with professional industry digital solutions
          </div>
          <div class="desc-line"></div>
        </div>
        <div class="location-img">
          <img src="../assets/images/home/local.png" alt="" />
        </div>
        <div class="location-carousel">
          <el-carousel
            height="432px"
            direction="vertical"
            :interval="3000"
            indicator-position="none"
          >
            <el-carousel-item v-for="(item, index) in swiperList" :key="index">
              <img :src="item" alt="" style="width: 128px; height: 432px" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- 我们能为您解决以下难点 -->
    <div class="resolve">
      <div class="resolve-content">
        <div class="resolve-title">
          <div class="resolve-name">我们能为您解决以下难点</div>
          <div class="resolve-name-eng">
            We can solve the following difficulties for you
          </div>
        </div>
        <div>
          <div class="resolve-tab">
            <div
              v-for="(item, index) in resolveList"
              :key="index"
              class="resolve-tab-item"
              @mouseenter="resolveActive = index + 1"
            >
              <div
                class="resolve-tab-name"
                :style="{
                  color: resolveActive == index + 1 ? '#0F3391' : '#515151',
                }"
              >
                {{ item.name }}
              </div>
              <div
                class="resolve-tab-line"
                v-if="resolveActive == index + 1"
              ></div>
            </div>
          </div>
          <div class="resolve-imgs">
            <div
              v-for="(item, index) in resolveList[resolveActive - 1].list"
              :key="index"
              class="resolve-imgs-item"
            >
              <img :src="item.url" alt="" />
              <div class="resolve-imgs-item-text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 康米斯大数据 -->
    <div class="data">
      <div class="data-content">
        <div class="name-title" style="padding: 88px 0 72px 0">
          <div class="name-name">康米斯大数据</div>
          <div class="name-name-eng">Comis Big Data</div>
        </div>
        <div class="data-list">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            class="data-list-item"
          >
            <div class="data-list-item-num">{{ item.num }}</div>
            <div class="data-list-item-text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 客户案例 -->
    <div class="partners" style="background: #f5f5f5; padding-bottom: 150px">
      <div class="partners-content">
        <div class="name-title" style="padding: 88px 0 30px 0">
          <div class="name-name">客户案例</div>
          <div class="name-name-eng">Customer stories</div>
        </div>
        <div class="partners-carousel">
          <el-carousel
            :interval="3000"
            type="card"
            height="520px"
            indicator-position="none"
            @change="changeCarouselCard($event)"
          >
            <el-carousel-item
              v-for="(item, index) in partnersList"
              :key="index"
            >
              <!-- <div 
             :style="{backgroundImage: 'url('+item.url+')'}"
             class="img"
             >
             </div> -->
              <div class="carousel">
                <img :src="item.url" alt="" srcset="" />
                <div class="carousel-text" v-if="carouselCardIndex == index">
                  <div>{{ item.text }}</div>
                  <div style="margin-top: 8px; font-size: 14px">
                    {{ item.text2 }}
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- <myCarsouselItem></myCarsouselItem> -->
    <!-- 合作伙伴生态 -->
    <div class="partners">
      <div class="partners-content">
        <div class="name-title" style="padding: 88px 0 30px 0">
          <div class="name-name">合作伙伴生态</div>
          <div class="name-name-eng">Partner ecosystem</div>
        </div>
        <!-- <img src="../assets/images/home/iconList.png" alt="" /> -->
        <div style="width: 1200px; overflow: hidden; height: 370px">
          <homes></homes>
        </div>
      </div>
    </div>

    <!-- 首页 -->
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>

<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";
import homes from "./home-s.vue";
// import myCarsouselItem from '../components/carousel.vue';

export default {
  components: { heade, bottom, homes },
  data() {
    return {
      bannerHeight: 0,
      iconActive: 0,
      resolveActive: 1,
      resolveImgList: [],
      bannerList: [
        require("../assets/images/home/banner1.png"),
        require("../assets/images/home/banner2.png"),
        require("../assets/images/home/banner3.png"),
      ],
      businessList: [
        {
          url: require("../assets/images/home/business11.png"),
          name: "人员位置管理",
          id: "1001",
        },
        {
          url: require("../assets/images/home/business22.png"),
          name: "车辆位置管理",
          id: "1002",
        },
        {
          url: require("../assets/images/home/business33.png"),
          name: "资产位置管理",
          id: "1003",
        },
      ],
      resolveList: [
        {
          name: "人员位置管理",
          list: [
            {
              url: require("../assets/images/home/resolve1.png"),
              text: "外勤人员作业消极怠工，效率低下",
            },
            {
              url: require("../assets/images/home/resolve2.png"),
              text: "无数据对比，考核有困难",
            },
            {
              url: require("../assets/images/home/resolve3.png"),
              text: "意外事故定位困难，耽误救险",
            },
          ],
        },
        {
          name: "车辆位置管理",
          list: [
            {
              url: require("../assets/images/home/resolve4.png"),
              text: "流程繁琐，管理效率低",
            },
            {
              url: require("../assets/images/home/resolve5.png"),
              text: "费用异常，难核实",
            },
            {
              url: require("../assets/images/home/resolve6.png"),
              text: "车辆事故频发，安全隐患大",
            },
          ],
        },
        {
          name: "资产位置管理",
          list: [
            {
              url: require("../assets/images/home/resolve7.png"),
              text: "运输途中被偷盗、损坏",
            },
            {
              url: require("../assets/images/home/resolve8.png"),
              text: "实时状态难监管",
            },
            {
              url: require("../assets/images/home/resolve9.png"),
              text: "资产盘点效率低下",
            },
          ],
        },
      ],
      dataList: [
        {
          num: "800W+",
          text: "Iot设备连接",
        },
        {
          num: "200W+",
          text: "平台日活跃",
        },
        {
          num: "20W+",
          text: "同时在线",
        },
        {
          num: "300T",
          text: "月上传数据",
        },
        {
          num: "1W+",
          text: "日均风险提醒",
        },
        {
          num: "1.26亿Km",
          text: "日均跟踪里程数",
        },
        {
          num: "300+",
          text: "覆盖城市",
        },
        {
          num: "5000+",
          text: "合作伙伴",
        },
      ],
      swiperList: [
        require("../assets/images/home/swiper1.png"),
        require("../assets/images/home/swiper2.png"),
        require("../assets/images/home/swiper3.png"),
        require("../assets/images/home/swiper4.png"),
        require("../assets/images/home/swiper5.png"),
        require("../assets/images/home/swiper6.png"),
        require("../assets/images/home/swiper7.png"),
      ],
      partnersList: [
        {
          url: require("../assets/images/home/partners1.png"),
          text: "迪安诊断",
          text2:
            "通过带温湿度监测功能的IOT智能设备，达到了对样本的时效管理，样本的温湿度监测，样本轨迹监控，极大了提高了样本运输过程中的风险管控。",
        },
        {
          url: require("../assets/images/home/partners2.png"),
          text: "徐工集团",
          text2:
            "资产定位管控，挖掘机等大型工程设备租赁，通过监控车辆的工作状态，作业时长，远程管理设备的工作状态，大大提高了企业对资产的利用率，以及租赁过程中的风险管控",
        },
        {
          url: require("../assets/images/home/partners3.png"),
          text: "中国铁通",
          text2:
            "20000+网络巡检、宽带安装服务人员定位管理，通过监控外勤作业人员的服务时间和人员定位轨迹，对企业统一管理外勤作业人员提供了极大的便利。",
        },
        {
          url: require("../assets/images/home/partners4.png"),
          text: "国家电网",
          text2:
            "帮助国家电网30000+巡检人员，故障维修处理，外勤人员，2600多辆巡检车，解决了公车私用的问题。",
        },
        {
          url: require("../assets/images/home/partners5.png"),
          text: "运满满",
          text2:
            "对货物运输挂靠车辆进行了统一的管理，帮助企业解决了挂靠货运约束难的痛点。",
        },
        {
          url: require("../assets/images/home/partners6.png"),
          text: "中储智运",
          text2:
            "通过IOT智能设备，不间断上传位置信息，极快地帮助企业实现管理货物运输过程中的人员管理和位置管理。",
        },
      ],
      carouselIndex: 0,
      carouselCardIndex: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.imgLoag();
    window.addEventListener(
      "resize",
      () => {
        this.imgLoag();
      },
      false
    );
  },
  methods: {
    clickBusiness(id) {
      localStorage.setItem("solutionId", id);
      this.$router.push(`/solution?id=${id}`);
    },
    changeCarousel(e) {
      setTimeout(() => {
        this.carouselIndex = e;
      }, 100);
    },
    changeCarouselCard(e) {
      this.carouselCardIndex = e;
      console.log(e, ";;;;;;;;;;;;;;;");
    },
    imgLoag() {
      this.$nextTick(() => {
        const page = document.getElementById("page");
        const pageWidth = page.offsetWidth;
        this.bannerHeight = (pageWidth * 960) / 1920;
      });
    },
  },
};
</script>
<style >
/* .el-icon-arrow-left:before {
  content: "" !important;
}
.el-icon-arrow-right:before {
  content: "" !important;
}
.el-carousel__arrow {
  width: 0px !important;
} */
</style>
<style lang="less" scoped>
/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 20px;
  height: 20px;
  background: #ffffff;
  // border: 1px solid #ffffff;
  border-radius: 50%;
  opacity: 0.7;
  margin-left: 24px;
  margin-right: 24px;
}
/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 50%;
  opacity: 1;
}
/deep/ .el-carousel__indicators {
  // 指示灯位置
  left: unset;
  transform: unset;
  left: 50%;
  transform: translateX(-50%);
}
.el-carousel__arrow {
  width: 0;
}
// 主营业务
.business {
  height: 781px;
  text-align: center;
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;

  .business-title {
    padding-top: 88px;
    padding-bottom: 48px;
    .business-name {
      font-weight: bold;
      font-size: 40px;
      color: #2c2c2c;
    }
    .eng {
      font-size: 18px;
      color: #2c2c2c;
      font-weight: 300;
    }
  }

  .business-img {
    display: flex;
    justify-content: center;
  }
  .business-item {
    width: 360px;
    height: 400px;
    margin-right: 40px;
    cursor: pointer;
    .business-item-name {
      height: 40px;
      font-weight: bold;
      font-size: 30px;
      color: #2c2c2c;
      margin-top: 40px;
    }
  }
  img {
    width: 360px;
    height: 400px;
  }
  .business-item:last-child {
    margin-right: 0;
  }
}
// 定位
.location {
  width: 100vw;
  height: 720px;
  background: linear-gradient(314deg, #000000 0%, #090909 100%);
  .location-content {
    width: 1200px;
    min-width: 1200px;
    height: 720px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .location-carousel {
      width: 128px;
      width: 432px;
    }
    .location-img {
      width: 509px;
      height: 388px;
      margin-left: 49px;
      margin-right: 53px;
      img {
        width: 509px;
        height: 388px;
      }
    }
    .location-desc {
      .desc-name {
        width: 445px;
        font-size: 26px;
        color: #ffffff;
        line-height: 40px;
      }
      .desc-name-eng {
        width: 445px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
      }
      .desc-line {
        width: 72px;
        height: 4px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        margin-top: 56px;
      }
    }
  }
}
// 我们能为您解决以下难点
.resolve {
  width: 100vw;
  height: 736px;
  background: #f5f5f5;
  text-align: center;
  .resolve-content {
    width: 1200px;
    min-width: 1200px;
    height: 736px;
    margin: 0 auto;
    .resolve-title {
      padding: 80px 0 56px 0;
    }
    .resolve-name {
      font-size: 32px;
      color: #2c2c2c;
      text-align: center;
    }
    .resolve-name-eng {
      font-weight: 300;
      font-size: 18px;
      color: #707070;
    }
    .resolve-tab {
      display: flex;
      border-bottom: 2px solid #bfbfbf;
      .resolve-tab-item {
        width: 400px;
        text-align: center;
        padding: 25px 0;
        position: relative;
      }
      .resolve-tab-line {
        width: 118px;
        height: 4px;
        background: #0f3391;
        border-radius: 3px 3px 3px 3px;
        margin: 0 auto;
        position: absolute;
        top: 81px;
        left: 50%;
        transform: translateX(-50%);
      }
      .resolve-tab-name {
        cursor: pointer;
        font-weight: 400;
        font-size: 24px;
        color: #515151;
      }
    }
    .resolve-imgs {
      display: flex;
      margin-top: 64px;
      .resolve-imgs-item {
        position: relative;
        .resolve-imgs-item-text {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 50%;
          line-height: 53px;
          height: 53px;
          transform: translateX(-50%);
          font-size: 16px;
          color: #ffffff;
          background: linear-gradient(
            180deg,
            rgba(44, 44, 44, 0) 0%,
            #2c2c2c 100%
          );
        }
      }
    }
  }
}
// 康米斯大数据
.data {
  width: 100vw;
  height: 615px;
  background: #ffffff;
  text-align: center;
  .data-content {
    width: 1200px;
    min-width: 1200px;
    height: 615px;
    margin: 0 auto;
  }
  .data-list {
    display: flex;
    flex-wrap: wrap;
    .data-list-item {
      width: 300px;
      margin-bottom: 64px;
      .data-list-item-num {
        font-weight: bold;
        font-size: 48px;
        color: #2c2c2c;
      }
      .data-list-item-text {
        font-weight: 300;
        font-size: 24px;
        color: #2c2c2c;
        margin-top: 16px;
      }
    }
  }
}
@keyframes fade-in {
  0% {
    opacity: 0; /* 开始时完全透明 */
  }
  100% {
    opacity: 1; /* 结束时完全不透明 */
  }
}
.carousel:hover {
  .carousel-text {
    display: block !important;
    // animation-name: fade-in; /* 应用动画名称为box1 */
    // animation-duration: 0.1s; /* 动画持续时间为3秒 */
  }
}
.partners {
  width: 100vw;
  height: 657px;
  background: #ffffff;
  text-align: center;
  .partners-content {
    width: 1200px;
    min-width: 1200px;
    height: 657px;
    margin: 0 auto;
  }
  .partners-carousel {
    .carousel-text {
      display: none;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 400px;
      // height: 120px;
      font-weight: 300;
      padding: 10px 20px;
      box-sizing: border-box;
      font-size: 18px;
      color: #ffffff;
      text-align: left;
      background-color: #000000;
      opacity: 0.7;
    }
    img {
      width: 400px;
      height: 520px;
      // background-size: 100% 100%;
    }
  }
}
.name--title {
  padding: 80px 0 56px 0;
}
.name-name {
  font-size: 32px;
  color: #2c2c2c;
  text-align: center;
}
.name-name-eng {
  font-weight: 300;
  font-size: 18px;
  color: #707070;
}
.carousel-item {
  position: relative;
  .img-text {
    position: absolute;
    left: 18.7%;
    top: 33.4%;
    width: 622px;
    height: 360px;
    img {
      width: 622px;
      height: 360px;
    }
  }
}
</style>
