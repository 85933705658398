<template>
  <div class="bottom">
    <div class="bottom-content">
      <div class="logo">
        <img src="../assets/images/home/logo-bottom.png" alt="" />
      </div>
      <div class="list">
        <div
          v-for="(item, index) in list"
          :key="index"
          :style="{ width: item.width, marginRight: item.marginRighet }"
          class="list-item"
        >
          <div class="list-item-name">{{ item.name }}</div>
          <div v-if="item.name != '联系方式'">
            <div v-for="(ele, i) in item.list" :key="i" class="item-item">
              {{ ele }}
            </div>
          </div>
          <div v-else :style="{ width: item.width }">
            <div class="tel-item">
              <img src="../assets/images/home/tel.png" alt="" />
              <div class="tel">400-669-0980</div>
            </div>
            <div class="tel-item">
              <img src="../assets/images/home/address.png" alt="" />
              <div class="tel">
                <span>杭州市</span><span>滨江区</span
                ><span>西兴街道</span>阡陌路
              </div>
            </div>
            <div class="address">482号智慧e谷A座5层</div>
            <div class="qrcode">
              <img src="../assets/images/home/qrcode.png" alt="" />
              <div class="qrcode-text">扫描二维码下载app</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-line"></div>
      <div class="beian" @click="goPage">浙ICP备2023001088号-11</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          name: "人员位置管理",
          width: "208px",
          marginRighet: "40px",
          list: ["外勤人员管理", "室内人员管理"],
        },
        {
          name: "车辆位置管理",
          width: "208px",
          marginRighet: "40px",
          list: [
            "汽车金融风控",
            "外勤作业车辆",
            "货运物流车辆",
            "应急保障车辆",
            "网约租赁车辆",
            "政企公车",
            "工程车辆",
            "特种车辆",
          ],
        },
        {
          name: "资产位置管理",
          width: "208px",
          marginRighet: "40px",
          list: [
            "贵重物品管理",
            "固定资产管理",
            "包装设备管理",
            "工程设备管理",
          ],
        },
        {
          name: "产品中心",
          width: "96px",
          marginRighet: "72px",
          list: ["人员定位终端", "车载定位终端", "资产定位终端", "其他"],
        },
        {
          name: "联系方式",
          width: "251px",
          marginRighet: "0px",
          // list:[
          //   '外勤人员管理',
          //   '室内人员管理',
          // ]
        },
      ],
    };
  },
  mounted() {},
  methods: {
    goPage() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index")
    },
  },
};
</script>
<style lang="less" scoped>
.bottom {
  height: 602px;
  width: 100%;
  background-color: #2c2c2c;
}
.bottom-content {
  width: 1200px;
  height: 100%;
  padding: 48px 0;
  min-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}
.logo {
  width: 200px;
  height: 56px;
  margin-bottom: 32px;
  img {
    width: 200px;
    height: 56px;
  }
}
.list {
  display: flex;
  .list-item-name {
    width: 120px;
    height: 26px;
    font-size: 20px;
    color: #ffffff;
    // margin-bottom:24px;
  }
  .item-item {
    width: 208px;
    height: 21px;
    font-size: 16px;
    color: #ffffff;
    margin-top: 24px;
  }
}
.tel-item {
  display: flex;
  margin-top: 25px;
  img {
    width: 20px;
    height: 20px;
  }
}
.tel {
  font-size: 16px;
  color: #ffffff;
  margin-left: 8px;
  span {
    margin-right: 4px;
  }
}
.address {
  margin-top: 10px;
  font-size: 16px;
  color: #ffffff;
}
.qrcode {
  margin-top: 24px;
  img {
    width: 104px;
    height: 104px;
    margin-bottom: 16px;
  }
  .qrcode-text {
    font-size: 16px;
    color: #ffffff;
  }
}
.bottom-line {
  width: 1200px;
  height: 0px;
  border: 1px solid #707070;
  margin-top: 40px;
  margin-bottom: 16px;
}
.beian {
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
</style>
