import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/example",
    name: "Example",
    component: () =>
      import("../views/example.vue"),
  },
  {
    path: "/aboutMine",
    name: "AboutMine",
    component: () =>
      import( "../views/aboutMine.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import( "../views/product.vue"),
  },
  {
    path: "/solution",
    name: "solution",
    component: () =>
      import( "../views/solution.vue"),
  },
  {
    path: "/customerCases",
    name: "customerCases",
    component: () =>
      import( "../views/customerCases.vue"),
  },
  {
    path: "/newsCenter",
    name: "newsCenter",
    component: () =>
      import( "../views/newsCenter.vue"),
  },
  {
    path: "/newsCenterdetail",
    name: "newsCenterdetail",
    component: () =>
      import( "../views/newsCenterdetail.vue"),
  },
];

const router = new VueRouter({
  routes,
});
// router.beforeEach((to,from,next) => {
//   if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
//     console.log("h5")
//       window.location.href = "";
//     }
//   // 以下可以做一些路由判断
//   next(); //不写这一句会无法跳转
// });

export default router;

