<template>
  <div class="box">
    <div v-for="(item, index) in dataList" :key="index">
      <div class="box_img">
        <img :src="item.url" alt="" class="img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      dataList: [
        {
          url: require("@/assets/images/home/s1.jpg"),
        },
        {
          url: require("@/assets/images/home/s2.jpg"),
        },
      ],
    };
  },
  created() {
    this.timer = setInterval(() => {
      let data = [
        {
          url: require("@/assets/images/home/s1.jpg"),
        },
        {
          url: require("@/assets/images/home/s2.jpg"),
        },
      ];
      this.dataList = this.dataList.concat(data);
      console.log(this.dataList, "lllllllllll");
    }, 7000);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style scoped>
.box_img {
  /* 禁止图片在容器内缩小 */
  flex-shrink: 0;
  /* width: 150px;     */
  height: 368px;
  /* width: 1200px; */
  text-align: center;
  /* margin: 0 50px;   */
  transition: transform 0.3s ease;
}
.img {
  height: 368px;
}

/* 当鼠标悬停在伙伴图片上时，图片会放大到1.1倍 */
/* .box_img:hover {  
  transform: scale(1.1);  
}   */

/* 定义了一个名为scrollImg的关键帧动画 */
@keyframes scrollImg {
  /* 动画开始，图片位置不变 */
  0% {
    transform: translateX(0);
  }
  /* 动画结束，图片向左移动自身宽度的197%（假设是为了让图片滑动出可视区域） */
  100% {
    transform: translateX(-197%);
  }
}

.box {
  /* 使用Flex布局 */
  display: flex;
  /* 子项在交叉轴上的对齐方式为中心 */
  align-items: center;
  /* 设置盒子高度为100px */
  height: 100px;
  /* 设置盒子的上边距为100px */
  margin-top: 100px;
  /* 应用scrollImg动画，持续时间为60秒，速度为线性，无限循环 */
  animation: scrollImg 60s linear infinite;
}

/* 当鼠标悬停在盒子上时，暂停动画 */
.box:hover {
  animation-play-state: paused;
}
</style>
