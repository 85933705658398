<template>
  <router-view />
</template>
<script>
export default {
  created() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    var mobile =
      /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(
        userAgent.toLowerCase()
      );

    console.log(mobile);
    if (mobile) {
      location.replace("https://kangmisi.com/h5/");
    }
  },
};
</script>
<style>
/*初始化样式*/
@import "./assets/css/initial.css";
/*公共样式*/
@import "./assets/css/public.css";
</style>
<style lang="less">
/*全局样式*/
@import "./assets/css/base.less";
</style>
